import React, { useEffect, useState } from 'react'
import './App.css';
import { Amplify } from 'aws-amplify'
import { DataStore } from '@aws-amplify/datastore';
import { RSVP } from './models';
import awsExports from "./aws-exports";
Amplify.configure(awsExports);

const initialState = {name: '', guests: '', foodAllergies: '', attending: 'select'}


function App() {
  const [form, updateForm] = useState(initialState)
  const [guests, setGuests] = useState([])

  useEffect(() => {
    coming()
  },[])

  async function create(event){
    event.preventDefault();
    console.log('taking form data putting it in form')
    const rsvpData = {...form}
    console.log('form complete', rsvpData)
    console.log('trying to send data');
    await DataStore.save( 
      new RSVP(rsvpData)
    );
    console.log('Successfully submited your RSVP')
    updateForm(initialState);
    console.log('original state updated')
  }

  async function coming(){
    console.log('fetching guests')
    // const models = await DataStore.query(RSVP);
    const models = await DataStore.query(RSVP, c=>c.attending('eq', true))
    console.log('guests fetched')
    setGuests(models)
    console.log('setting Guests')

  }
  return (
    <div className="App">
      <h1 className='text-5xl font-bold mt-0 mb-6 color-gold'>Holiday Party RSVP</h1>
      
      <div className='totalrsvp'><p>Total RSVP's: {guests.length}</p></div>
      
      <div className="block p-6 rounded-lg shadow-lg border-solid border-2 border-gold bg-white max-w-md">
  <form onSubmit={create}>
    <div className="form-group mb-6">      
    <label className="form-label inline-block mb-2 text-gold">Name</label>
      <input type="text" className="form-control block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" 
        id="exampleInput7"
        onChange={e=>updateForm({...form, 'name': e.target.value})}
        value={form.name}
        placeholder="Full Name"/>
    </div>
    <div className="form-group mb-6">
    <label className="form-label inline-block mb-2 text-gold">Will you be attending?</label>
    <select value={form.attending} onChange={e=>updateForm({...form, 'attending': e.target.value})} 
    className="form-select appearance-none
      block
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding bg-no-repeat
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" 
      aria-label="Default select example">
        <option key={1} value='select'>Select</option>
        <option key={2} value='attending'>Yes, I can't wait!</option>
        <option key={3} value='no'>No, sadly I can't make it</option>
    </select>
  </div>
    <div className="form-group mb-6">
    <label className="form-label inline-block mb-2 text-gold">Number of guests you are RSVP'ing for?</label>
      <input onChange={e=>updateForm({...form, 'guests': Number(e.target.value)})}
          value={form.guests} type="number" className="form-control block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" id="exampleInput8"
        placeholder="Number of guests"/>
    </div>
    <div className="form-group mb-6">
    <label className="form-label inline-block mb-2 text-gold">Any food allergies?</label>
      <textarea onChange={e=>updateForm({...form, 'foodAllergies': e.target.value})}
        value={form.foodAllergies}
      className="
        form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
      "
      id="exampleFormControlTextarea13"
      rows="3"
      placeholder="Message"
    ></textarea>
    </div>
    <button type="submit" className="
      w-full
      px-6
      py-2.5
      bg-gold
      text-white
      font-medium
      text-xs
      leading-tight
      uppercase
      rounded
      shadow-md
      hover:bg-gold hover:shadow-lg
      focus:bg-gold focus:shadow-lg focus:outline-none focus:ring-0
      active:bg-gold active:shadow-lg
      transition
      duration-150
      ease-in-out">RSVP</button>
  </form>
</div>
    </div>
    
  );
}

export default App;
